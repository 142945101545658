import { scriptElementTypes } from "./../integration/ScriptTools"
import ChatBot from "./../integration/ChatBot"
import Vue from "vue"

let scriptProgressTimeout

export default {
  namespaced: true,
  state: {
    // scriptCollection: [],
    hairState: null,
    scriptData: [],
    scriptSequence: [],
    sequenceHistory: [],
    scriptFlags: {},
    quizStatus: "pre",
    quizResponses: {}
  },

  mutations: {
    // setScriptCollection(state, _collection) {
    //   state.scriptCollection = _collection
    // },
    setHairState(state, data) {
      state.hairState = data
    },
    setScriptData(state, data) {
      state.scriptData = data
    },
    setScriptSequence(state, data) {
      state.scriptSequence = data
    },
    setSequenceHistory(state, history) {
      state.sequenceHistory = history
    },
    setScriptFlags(state, _flags) {
      state.scriptFlags = _flags
    },
    setQuizStatus(state, _status) {
      state.quizStatus = _status
    },
    setQuizResponse(state, { key, value }) {
      Vue.set(state.quizResponses, key, value)
    },
    resetResponses(state) {
      state.quizResponses = {}
    }
  },

  actions: {
    async importScript({ commit }) {
      // get the script from the payload
      console.log("importScript")
      let { script, hairState } = await ChatBot.getHairQuizScript()
      console.log(script)

      // set the script sequence to the first item in the elements array
      commit("setScriptData", script.scriptElements)
      commit("setHairState", hairState)
    },

    initScript({ state, commit }) {
      // put the first element from the script elements array into the sequencer
      clearTimeout(scriptProgressTimeout)
      commit("setSequenceHistory", [])
      commit("setScriptSequence", [state.scriptData[0].id])

      // kick off
      // dispatch("incrementScript")
      // dispatch("UPDATE_SCRIPT_FLAGS", { IS_RETURN_TO_CHAT: true })
    },

    incrementScript({ state, dispatch, getters }) {
      // the _activeElement will be the scriptElement with an id matching the 'first' item in the scriptSequence
      let _activeElement = state.scriptData[getters.getScriptDataIndex]
      // // track the index of the current script element (to handle automatic progression to the next element in the script)

      // if this is an action element, dispatch the action with its payload (eg 'MAKE_IT_GREEN', { colour: '#00FF00' })
      if (_activeElement.type == scriptElementTypes.ACTION.type) {
        dispatch(_activeElement.action, _activeElement.payload)
        // if (
        //   ["REFRESH_USER_ACCOUNT_INFO", "UPDATE_SCRIPT_FLAGS"].includes(
        //     _activeElement.action
        //   )
        // ) {
        //   dispatch(
        //     "appendSequence",
        //     state.scriptData[getters.getScriptDataIndex + 1].id
        //   )
        //   dispatch("incrementScript")
        // }
      }

      // if (_activeElement.type == scriptElementTypes.DECISION.type) {
      //   // if it's a decision element, append the sequence according to the query's result (eg 'IS_USER_LOGGED_IN')
      //   dispatch(
      //     "appendSequence",
      //     state.scriptData[getters.getScriptDataIndex].responseSequence[
      //       getters.CHECK_SCRIPT_FLAGS(_activeElement.query)
      //     ]
      //   )
      //   dispatch("incrementScript")
      // }
    },

    handleResponse({ state, commit, dispatch, getters }, responseObject) {
      let { key, value } = responseObject.response
      //console.log("handleResponse", responseObject)
      commit("setQuizResponse", {
        key,
        value
      })

      // TODO: probably call this from a more sensible place?
      responseObject.callback &&
        responseObject.callback(value).catch(error => {
          console.log("*** error submitting hair state", error)
        })

      dispatch(
        "appendSequence",
        // for the selected answer, if it's a single response (not multiselect), and if there is a responseSequence on the question, append it to the sequence
        !Array.isArray(value) &&
          state.scriptData[getters.getScriptDataIndex].responseSequence
          ? state.scriptData[getters.getScriptDataIndex].responseSequence[value]
          : // else just progress to the next item in the script
            state.scriptData[getters.getScriptDataIndex + 1].id
      )

      // // If the script has a 'submitResponse' function defined on it, then use it to submit the responses
      // // TODO: Should we wait on a successful response before progressing to the next question? If not, should we retry in the background if it fails?
      // // NB: This is deprecated for the hair quiz just now, but may come in handy so it's staying in
      // ChatBot.scripts[state.scriptCollection[0]]?.submitResponse?.(
      //   responseObject
      // )

      // Crack on
      dispatch("incrementScript")
    },

    appendSequence({ state, dispatch, commit }, sequence) {
      // push the new item(s) to the script sequence and then remove the 'current' item from the beginning

      // NB - To allow reversal in the script we must store the results of the selected responseSequence
      // Sequence priority: https://docs.google.com/document/d/11l4NRnws9-SECJ97iJLHFIvekUNr7hqvt8ATf3vrUxo
      // if there is already more than one item in the existing scriptSequence, ignore the newly supplied sequence

      let _tempSequence = [...state.scriptSequence]

      let _commitSequence = false

      if (_tempSequence.length <= 1) {
        _tempSequence.push(...[].concat(sequence))
        _commitSequence = true
      }

      dispatch("pushSequenceHistory", {
        id: _tempSequence[0],
        sequence: _commitSequence ? sequence : null
      })
      _tempSequence.shift()
      commit("setScriptSequence", _tempSequence)
    },

    reverseScript({ state, commit, dispatch }, num = 1) {
      // if the are any advance items in the sequence, we should keep them plus prepend the script item(s) we're reverting to

      // get the elements we're reverting to
      let historyElements = state.sequenceHistory.slice(-num)
      //console.log("historyElements", historyElements)

      if (historyElements[0].sequence) {
        // if the first history element has a script sequence, then that means it was set, which means that we should clear the script sequence out for whatever might be injected
        commit("setScriptSequence", [historyElements[0].id])
      } else {
        // prepend them to the forward sequence
        // console.log("no responseSequence on", historyElements[0].id)
        commit(
          "setScriptSequence",
          historyElements
            .map(element => element.id)
            .concat(state.scriptSequence)
        )
      }

      // remove them from the history
      dispatch("sliceSequenceHistory", num)
    },

    pushSequenceHistory({ state, commit }, payload) {
      // console.log("pushHistory", payload)
      commit("setSequenceHistory", state.sequenceHistory.concat(payload))
    },

    sliceSequenceHistory({ state, commit }, num) {
      commit("setSequenceHistory", state.sequenceHistory.slice(0, -num))
    },

    ///////////////////////////////////
    ////// SCRIPT-TRIGGERED EVENTS ////
    ///////////////////////////////////

    async NEW_QUIZ({ dispatch }) {
      // commit("setScriptCollection", scriptCollection)
      await dispatch("importScript")
      dispatch("initScript")
    },

    SET_QUIZ_STATUS({ commit }, status) {
      if (["pre", "active", "post"].includes(status))
        commit("setQuizStatus", status)
    }
  },

  getters: {
    getScriptDataIndex: state => {
      return state.scriptData.findIndex(
        element => element.id == state.scriptSequence[0]
      )
    },

    getCurrentQuestion: (state, getters) => {
      return state.scriptData[
        getters.getScriptDataIndex
      ] /*  &&
        state.scriptData[getters.getScriptDataIndex].conversationElement */
    },

    CHECK_SCRIPT_FLAGS: (state, dispatch, rootState, rootGetters) => flag => {
      switch (flag) {
        // checking login state isn't a script flag, as it will be set state before the CUI opens. There may be others in future that lead to this getting a bit bloated.
        case "IS_USER_LOGGED_IN": {
          return (!!rootState.identity.authJWT).toString()
        }

        // yup, already added another. This may get messy...
        case "EXISTING_HAIR_STATE":
          /* console.log(
            "existing hair state?",
            !!rootGetters["account/getHairState"],
            rootGetters["account/getHairState"]
          ) */
          return (!!rootGetters["account/getHairState"]).toString()
        default:
          return (!!state.scriptFlags[flag]).toString()
      }
    }
  }
}
