const dieselgateStore = {
  namespaced: true,

  state: {
    testCondition: false,
    componentLoadManifest: [],
    pageLoadIdle: false
  },

  mutations: {
    SET_TEST_CONDITION: (state, _val) => {
      state.testCondition = _val
    },

    SET_COMPONENT_MANIFEST: (state, _val) => {
      state.componentLoadManifest = _val
    },

    SET_PAGE_LOAD_IDLE: (state, _val) => {
      state.pageLoadIdle = _val
    }
  }

  // actions: {
  //   ADD_COMPONENT_TO_MANIFEST: ({ state, commit }, payload) => {
  //     if (payload && typeof payload == "string") {
  //       commit(
  //         "SET_COMPONENT_MANIFEST",
  //         [...state.componentLoadManifest].concat(payload)
  //       )
  //     }
  //   },

  //   REMOVE_COMPONENT_FROM_MANIFEST: ({ state, commit }, payload) => {
  //     if (payload && typeof payload == "string") {
  //       const newArr = [...state.componentLoadManifest]
  //       const index = newArr.indexOf(payload)
  //       newArr.splice(index, 1)
  //       commit("SET_COMPONENT_MANIFEST", newArr)
  //     }
  //   }
  // },

  // getters: {
  //   GET_ARE_PAGE_COMPONENTS_LOADED: state => {
  //     return state.componentLoadManifest.length == 0
  //   }
  // }
}

export default dieselgateStore
