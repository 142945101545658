import Vue from "vue"

/*  State consists of an error array
    Array of {
        code: String - serves as id
        message: String - human readable error
        type: String - refers to type or location of the error
    }                                  
*/
const errorStore = {
  namespaced: true,
  state: {
    errors: []
  },
  mutations: {
    ADD_ERROR: (state, payload) => {
      if (typeof payload == "object" && payload.message) {
        payload.message = payload.message.split("Username").join("Email")
        if (
          payload.message.includes(
            "Value at 'previousPassword' failed to satisfy constraint"
          )
        ) {
          payload.message = "Please ensure your password is correct."
        }
      }
      Vue.set(state, "errors", [...state.errors, payload])
    },
    REMOVE_ERROR: (state, payload) => {
      Vue.set(state, "errors", [
        ...state.errors.filter(error => error.code != payload.code)
      ])
    }
  },
  actions: {
    ERRORS_ADD({ commit }, payload) {
      commit("ADD_ERROR", payload)
    },
    ERRORS_REMOVE({ commit }, payload) {
      commit("REMOVE_ERROR", payload)
    }
  }
}

export default errorStore
