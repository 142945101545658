import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

import identity from "./identity"
import errors from "./errors"
import hairquiz from "./hairquiz"
import content from "./content"
import cart from "./cart"
import account from "./account"
import breakpoints from "./breakpoints"
import scroll from "./scroll"
import locale from "./locale"
import dieselgate from "./dieselgate"
import queryParams from "./queryParams"
import ui from "./ui"

Vue.use(Vuex)

let domain = /:\/\/([^/]+)/.exec(window.location.href)[1]

const cartState = createPersistedState({
  key:
    process.env.NODE_ENV == "production" ? domain + "_cart" : "bleach_dev_cart",
  paths: ["cart.lineItems", "cart.cartId"],
  storage: window.localStorage
})

const buuidState = createPersistedState({
  key: domain + "_buuid",
  paths: ["identity.buuid"],
  storage: window.sessionStorage
})

const localeState = createPersistedState({
  key: domain + "_locale",
  paths: ["locale.shopName", "locale.locale", "locale.timeZone"],
  storage: window.localStorage
})

const queryParamsState = createPersistedState({
  key: domain + "_params",
  paths: ["queryParams.queryParamsMap"],
  storage: window.sessionStorage
})

const accountState = createPersistedState({
  key: domain + "_account",
  paths: ["account.marketingPrefs"],
  storage: window.localStorage
})

const vueStore = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    breakpoints,
    scroll,
    identity,
    errors,
    hairquiz,
    content,
    cart,
    account,
    locale,
    dieselgate,
    queryParams,
    ui
  },
  plugins: [cartState, buuidState, localeState, accountState, queryParamsState]
})

export default vueStore
