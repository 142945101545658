import Vue from "vue"
import Auth from "@aws-amplify/auth"
import AuthHelper from "@/helpers/auth-helper"
import BleachAPI from "./../integration/BleachAPI"
import GTMAPI from "@/integration/GTMAPI"
import axios from "axios"
import router from "@/router"
import { generatePassword } from "@/utils"

const identityStore = {
  namespaced: true,
  state: {
    authJWT: null,
    buuid: null,
    userDetails: {},
    cognitoInfo: null,
    authInProgress: false
  },
  mutations: {
    SET_TOKEN: (state, { JWT }) => {
      Vue.set(state, "authJWT", JWT)
    },
    SET_BUUID: (state, id) => {
      state.buuid = id
    },
    SET_USERINFO: (state, { user }) => {
      state.userDetails = user
    },
    SET_COGNITOINFO: (state, { cognito }) => {
      state.cognitoInfo = cognito
    },
    SET_AUTH_PROGRESS_STATE: (state, _val) => {
      state.authInProgress = _val
    }
  },
  actions: {
    async identityEmailSignIn({ rootState }, payload) {
      const { username, postLogInRoute, isSignUp } = payload

      return Auth.signIn(username).then(() => {
        axios
          .post(
            `/.netlify/functions/login`,
            {
              email: username,
              store: router.options.base,
              postLogInRoute,
              cartId: rootState.cart.cartId,
              isSignUp
            },
            {
              headers: {
                Accept: "application/json"
              }
            }
          )
          .then(() => {
            GTMAPI.trackEvent("log_in.started")
          })
          .catch(e => {
            console.error("identityEmailSignIn POST ERROR", e)
            throw { code: "LoginLambdaError" }
          })
      })
    },

    async identityEmailSignUp({ dispatch }, payload) {
      const {
        username,
        firstName,
        lastName,
        subscribedToMarketing,
        postLogInRoute
      } = payload
      const password = generatePassword(32)
      const data = await Auth.signUp({
        username,
        password,
        attributes: {
          "custom:firstName": firstName,
          "custom:lastName": lastName,
          "custom:subscribedMark": String(subscribedToMarketing)
        }
      })

      return dispatch(
        "identityEmailSignIn",
        Object.assign({}, data.user, {
          isSignUp: true,
          postLogInRoute
        })
      )
    },

    async identitySignOut({ commit, dispatch, state }) {
      try {
        if (state.cognitoInfo) {
          await Auth.signOut()
        }
      } catch (error) {
        console.error({ code: "identitySignOutError", error })
      } finally {
        commit("SET_USERINFO", { user: null })
        commit("SET_COGNITOINFO", { cognito: null })
        commit("SET_TOKEN", { JWT: null })
        commit("SET_BUUID", null)
        dispatch("account/RESET_PROFILE", null, { root: true })
      }
    },

    async identityAnswerAuthChallenge({ dispatch }, payload) {
      const { code, email, isSignUp } = payload

      await dispatch("identitySignOut")
      await dispatch("getVerificationCode", {
        code,
        email,
        isSignUp
      })
    },

    async getVerificationCode({ commit, dispatch }, payload) {
      const { code, email, isSignUp } = payload

      let user

      try {
        user = await Auth.signIn(email)
        if (user && user.challengeName === "CUSTOM_CHALLENGE") {
          // to send the answer of the custom challenge
          const authenticatedUser = await Auth.sendCustomChallengeAnswer(
            user,
            code
          )
          await Auth.updateUserAttributes(authenticatedUser, {
            "custom:authChallenge": "claimed"
          })
        } else {
          throw Error("Challenge name mismatch")
        }
      } catch (error) {
        throw { code: "AmplifyChallengeError", error }
      }

      const authenticatedUser = await Auth.currentAuthenticatedUser().catch(
        error => {
          throw { code: "currentAuthenticatedUserError", error }
        }
      )

      commit("SET_USERINFO", {
        user: authenticatedUser.attributes
      })

      commit("SET_COGNITOINFO", { cognito: user })
      commit("SET_TOKEN", {
        JWT: user.signInUserSession
          ? user.signInUserSession.idToken.jwtToken
          : user.token
      })

      try {
        const profile = await BleachAPI.getUserProfile()
        const { buuid } = profile

        GTMAPI.trackEvent("log_in.completed", { user_id: buuid })
        commit("SET_BUUID", buuid)
        dispatch("account/SET_PROFILE", profile, { root: true })

        const hasUnsetAttributes =
          authenticatedUser.attributes["custom:firstName"] &&
          authenticatedUser.attributes["custom:lastName"] &&
          !profile.firstName &&
          !profile.lastName

        if (isSignUp || hasUnsetAttributes) {
          GTMAPI.trackEvent("registration.completed")

          await BleachAPI.updateUserAttributes({
            firstName: authenticatedUser.attributes["custom:firstName"],
            lastName: authenticatedUser.attributes["custom:lastName"],
            subscribedToMarketing:
              authenticatedUser.attributes["custom:subscribedMark"]
          }).then(res => {
            if (res.data.updateProfile.result) {
              dispatch("account/SET_PROFILE", res.data.updateProfile.user, {
                root: true
              })
            }
          })
        }
      } catch (error) {
        // TODO: This hangs the login flow, when in reality the user has probably logged in (we just failed to update attributes)
        throw { code: "updateUserAttributesError", error }
      }
    },

    async currentAuthenticatedUser({ commit, dispatch }) {
      commit("SET_AUTH_PROGRESS_STATE", true)
      try {
        await dispatch("refreshCurrentUser")

        await BleachAPI.getUserProfile()
          .then(profile => {
            const { buuid } = profile

            GTMAPI.trackEvent("log_in.completed", { user_id: buuid })
            commit("SET_BUUID", buuid)
            dispatch("account/SET_PROFILE", profile, { root: true })
          })
          .catch(error => {
            throw { code: "getUserProfileError", error }
          })
      } catch (error) {
        throw { code: error.code, error }
      } finally {
        commit("SET_AUTH_PROGRESS_STATE", false)
      }
    },

    async refreshCurrentUser({ commit }) {
      const user = await Auth.currentAuthenticatedUser().catch(error => {
        throw { code: "currentAuthenticatedUserError", error }
      })

      const { refreshToken } = await user.getSignInUserSession()

      await AuthHelper.refreshSessionPromise(user, refreshToken).catch(
        error => {
          throw { code: "refreshSessionError", error }
        }
      )

      commit("SET_USERINFO", {
        user: user.attributes
      })
      commit("SET_COGNITOINFO", { cognito: user })

      let token = user.signInUserSession
        ? user.signInUserSession.idToken.jwtToken
        : user.token
      commit("SET_TOKEN", { JWT: token })
    }
  },

  getters: {
    getIsUserLoggedIn: state => {
      return !!state.authJWT
    },
    getJWT: state => {
      return state.authJWT
    }
  }
}

export default identityStore
